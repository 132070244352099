<template>
  <div class="page__wrapper">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        title="经销商价值地图"
        name="1"
      >
        <!-- 头部区域 -->
        <page-header
          :base-info="baseInfo"
          :params.sync="params"
        />

        <!-- 基础信息面板 -->
        <base-info
          :base-info="baseInfo"
        />
      </el-collapse-item>
    </el-collapse>

    <el-row class="map-title">
      {{ $t('value_map.profitabilityMap') }}
    </el-row>

    <!-- 图例及导出等按钮 -->
    <mapLegend />

    <!-- Value Map 展示区域 -->
    <div
      id="mapWrapper"
      :style="{display:'flex', width: '1600px', '-webkit-transform': 'perspective(1px) scale(' + scale + ')', 'transform-origin': '0 0', outline: '1px solid transparent'}"
    >
      <!-- 树形图画布 -->
      <div
        v-if="canvasShow"
        id="container"
        :style="{flex: 1, height: canvasHeight +'px'}"
      >
        <div
          id="infovis"
        />
      </div>
      <!-- 指标详情 -->
      <indicator-panel
        :num="kpiNumber"
        :title="idcTitle"
        :height="idcPanelHeight"
        :data-list="kpiDataList"
      />
    </div>
  </div>
</template>
<script>
import pageHeader from './header'
import baseInfo from './baseInfo'
import mapLegend from './legend'
import indicatorPanel from './indicatorPanel'

const BASEURL = {
  tree: '/jlr/tt/valueMap/kpi',
  office: '/system/office/list'
}

export default {
  components: { pageHeader, baseInfo, mapLegend, indicatorPanel },
  data () {
    return {
      st: {},
      data: {},
      params: {},
      baseInfo: {},
      kpiDataList: [],
      canvasShow: true,
      canvasHeight: 680,
      idcTitle: '',
      activeNames: '1',
      kpiNumber: 0,
      idcPanelHeight: '',
      childrenList: {},
      isSpecialChild: false,
      scale: 1
    }
  },
  mounted () {
    const _this = this
    window.onresize = function (e) {
      _this.activeNames = '0'
    }
    this.scale = (document.getElementById('mapWrapper').parentNode.offsetWidth / 1600).toFixed(2)
    this.scale = this.scale >= 1 ? 1 : this.scale
    // this.canvasHeight = this.canvasHeight * this.scale
    this.loadByDefaultParams()
    if (!this.$store.state.app.isCollapse) {
      this.$nextTick(() => {
        document.getElementById('foldBtn').click()
      })
    }
  },
  methods: {
    export () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post('/jlr/tt/valueMap/export1', this.params)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    restore () {
      this.canvasShow = false
      this.$nextTick(() => {
        this.canvasShow = true
        this.$nextTick(() => {
          this.init()
        })
      })
    },
    loadByDefaultParams () {
      const params = { filter: [{ left: 'category', operate: '=', right: 'sys_office_category_3' }] }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.params.dealerId = respData.list && respData.list.length ? respData.list[0].id : ''
          this.params.dealerCode = respData.list && respData.list.length ? respData.list[0].code : ''
          this.loadData()
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    getBaseInfo (arr) {
      this.baseInfo = arr[0] && arr[0].titleMap ? arr[0].titleMap : {}
      this.baseInfo.cityMarketShar = this.data.children[1].children[0].children[0].children[0].children[0].children[0].children[0].children[0].data.list[16].kpiValue
    },
    loadData (params = {}) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })

      // 清空表单和指标详情
      this.baseInfo = {}
      this.kpiNumber = 0
      this.kpiDataList = []
      this.idcPanelHeight = '60px'

      if (!params.period) params.period = this.$moment().subtract(1, 'months').format('YYYYMM')
      if (!params.contrastType) {
        params.contrastType = 'all'
      }
      if (params.contrastType) {
        params.contrastTypeValue = this.$getDictLabel({ type: 'contrast_type', value: params.contrastType })
      }
      if (!params.calculateType) params.calculateType = 'MTD'
      if (!params.dealerId) {
        params.dealerId = this.params.dealerId
        params.dealerCode = this.params.dealerCode
      }
      this.params = params
      this.$axios
        .post(BASEURL.tree, params)
        .then(resp => {
          const respData = resp.data
          this.data = respData[0]
          this.getBaseInfo(respData)
          if ((Object.keys(this.st)).length !== 0) {
            // 重新渲染，暂时没找到合适的 api
            this.canvasShow = false
            this.$nextTick(() => {
              this.canvasShow = true
              this.$nextTick(() => {
                this.init()
              })
            })
          } else {
            this.init()
          }
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    init () {
      const _this = this
      const st = new $jit.ST({
        injectInto: 'infovis',
        duration: 600,
        constrained: false,
        transition: $jit.Trans.Quart.easeInOut,
        levelDistance: 30,
        levelsToShow: 2,
        offsetY: 260,
        offsetX: -150,
        orientation: 'top',
        // subtreeOffset: 50,
        // siblingOffset: -5,
        Navigation: {
          enable: true,
          panning: true
          // zooming: 60
        },
        Node: {
          overridable: true,
          width: 140,
          align: 'center',
          color: 'white'
        },
        Edge: {
          type: 'angleLine',
          overridable: true,
          color: '#bbb'
        },
        onCreateLabel: function (label, node) {
          label.id = node.id
          const list = node.data.list.filter(item => item.isShow === 'Y')

          const subtree = $jit.json.getSubtree(_this.data, node.id)

          const isThirdLevel = node.name.split('.').length < 3

          let base = `<div style="width:${isThirdLevel ? list.length * 140 : 140}px;border:1px solid #ccc;background:white;cursor:pointer; }">
                        <div style="width:100%;text-align:center;background:${node.name.split('.').length < 4 ? '#bbb' : '#e2e2e2'};height:22px;line-height:22px;font-weight:600;">
                          <div style="font-size:12px;">
                            ${node.name}
                          </div>
                        </div>
                        <div style="width:100%;display:flex;justify-content:space-around;">`

          // 第四级开始只显示标题
          if (isThirdLevel) {
            list.forEach(ele => {
              base += `<div style="width:100%;">
            <div style="display:flex;justify-content:space-around;align-items:center;height:22px;line-height:22px;">
              <div style="width:10px;height:10px;border-radius:10px;background-color:${ele.level === 'A' ? 'green' : (ele.level === 'B' ? 'orange' : (ele.level === 'C' ? 'pink' : (ele.level === 'D' ? 'red' : '#bbb')))};"></div>
              ${ele.rank || 0}
            </div>
            <div style="width:138px;background:#bbb;text-align:center;height:22px;line-height:22px;">
                ${ele.name}
            </div>
              <div style="background:#eee;width:138px;text-align:center;height:22px;line-height:16px;">
                <table style="width:100%;font-size:12px;">
                  <td>当期</td><td>上期</td><td>标杆</td>
                </table>
              </div>
              <div style="width:140px;text-align:center;height:22px;line-height:22px;">
                <table style="width:100%;font-size:12px;">
                  <td style="width:33%;">
                  ${ele.kpiValue || ''}
                  </td>
                  <td style="width:33%;">
                  ${ele.previousKpiValue || ''}
                  </td>
                  <td style="width:33%;">
                  ${ele.standard || ''}
                  </td>
                </table>
              </div>
            </div>`
            })
          }
          base += '</div></div>'

          let titles = ''
          if (subtree.children && subtree.children.length) genSpecSubNode(subtree.children)

          function genSpecSubNode (children) {
            if (children[0]) _this.childrenList[children[0].name] = children[0]
            if (node.name.split('.').length >= 2 && children && children.length > 0) {
              titles += `<div style="height:10px;display:flex;justify-content:center;"><div style="width:1px;height:100%;background:#ccc;"></div></div>
              <div style="height:30px;width:140px;background:${children[0].name.split('.').length < 4 ? '#bbb' : '#e2e2e2'};line-height:30px;border:1px solid #ccc;text-align:center;cursor:pointer;">
                <div style="font-size:12px;">
                  ${children[0].name}
                </div>
              </div>`
              if (children[0].children) {
                genSpecSubNode(children[0].children)
              }
            }
          }
          base += titles

          label.innerHTML = base

          label.onclick = () => {
            _this.kpiNumber = _this.kpiDataList.length
            if (!_this.isSpecialChild) {
              _this.idcTitle = node.name
              _this.kpiDataList = node.data.list
              _this.idcPanelHeight = _this.kpiDataList.length ? 56 + (_this.kpiDataList.length > 6 ? 6 : _this.kpiDataList.length) * 93 + 'px' : '60px'
            }
          }
          let arr = Array.from(label.children)
          arr.forEach(child => {
            child.onclick = (value) => {
              let name = value.srcElement.innerText
              if (_this.childrenList[name]) {
                _this.isSpecialChild = true
                _this.idcTitle = _this.childrenList[name].name
                _this.kpiDataList = _this.childrenList[name].data.list
                _this.idcPanelHeight = _this.kpiDataList.length ? 56 + (_this.kpiDataList.length > 6 ? 6 : _this.kpiDataList.length) * 93 + 'px' : '60px'
              } else {
                _this.isSpecialChild = false
              }
            }
          })
          /* label.children.forEach(child => {
            child.onclick = (value) => {
              let name = value.srcElement.innerText
              if (_this.childrenList[name]) {
                _this.isSpecialChild = true
                _this.idcTitle = _this.childrenList[name].name
                _this.kpiDataList = _this.childrenList[name].data.list
                _this.idcPanelHeight = _this.kpiDataList.length ? 56 + (_this.kpiDataList.length > 6 ? 6 : _this.kpiDataList.length) * 93 + 'px' : '60px'
              } else {
                _this.isSpecialChild = false
              }
            }
          }) */
        }
      })

      st.loadJSON(this.data)

      // 动态计算节点高度
      st.graph.eachNode(function (node) {
        const flag = node.name.split('.').length < 3
        node.setData('width', flag ? 140 * (node.data.list.filter(item => item.isShow === 'Y')).length : 140)
        node.setData('height', flag ? 112 : 24, node.name.split('.').length < 2 ? '' : 'end')
      })

      st.compute()

      this.st = st

      // 模拟点击根节点
      st.onClick(st.root)
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  margin:0;
  padding:0;
  font-size: 12px;
  .map-title {
    padding: 12px 0 4px 2px;
    font-weight: 600;
    border-bottom: 8px solid #eee;
    margin-bottom: 4px;
  }
  #container {
    position: relative;
    text-align: right;
  }
  #infovis {
    position: relative;
    height: 100%;
  }
  #float_banner{
position:fixed;
top:30;
left:0;
width:900px;
height:30px;
}
}
</style>

<style lang="less">
#mapWrapper {
  * {
    transform: perspective(1px) translate3d(0, 0, 0);
    outline: 1px solid transparent;
    backface-visibility: hidden;
  }
}
</style>
<style lang="less">
.page__wrapper {
    .el-collapse-item__header {
    font-size: 18px;
    font-weight: bold;
    padding-left: 35%;
  }
}
</style>
